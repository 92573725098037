import { NestedLink } from '../../components/MenuNavigationLink/MenuNavigationLink';
import RouteKeys from '../../../config/RouteKeys';
import { getPathFromRouteKey } from '../../shared/utils/getPathFromRouteKey';

const links: NestedLink[] = [
  {
    to: getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE),
    textKey: 'pageTitles.customerCentre',
    isPartiallyActive: true,
    nestedLinks: [
      {
        to: getPathFromRouteKey(RouteKeys.BORROWER),
        textKey: 'pageTitles.clientPortal',
      },
      {
        to: getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE.UNDERSTANDING_YOUR_MORTGAGE),
        textKey: 'pageTitles.understandingYourMortgage',
      },
      {
        to: getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE.RESIDENTIAL_MORTGAGES),
        textKey: 'pageTitles.residentialMortgages',
        nestedLinks: [
          {
            to: getPathFromRouteKey(
              RouteKeys.CUSTOMER_CENTRE.RESIDENTIAL_MORTGAGES.RENEW_YOUR_MORTGAGE
            ),
            textKey: 'pageTitles.renewMortgage',
          },
          {
            to: getPathFromRouteKey(
              RouteKeys.CUSTOMER_CENTRE.RESIDENTIAL_MORTGAGES.REFINANCING_MORTGAGE
            ),
            textKey: 'pageTitles.refinancingMortgage',
          },
          {
            to: getPathFromRouteKey(
              RouteKeys.CUSTOMER_CENTRE.RESIDENTIAL_MORTGAGES.PURCHASING_HOME
            ),
            textKey: 'pageTitles.purchasingHome',
          },
        ],
      },
      {
        to: getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE.NEXTJOB_PROGRAM),
        textKey: 'pageTitles.nextJobProgram',
      },
      {
        to: getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE.RESOURCES),
        textKey: 'pageTitles.resources',
        nestedLinks: [
          // {
          //   to: getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE.COVID19_FAQ),
          //   textKey: 'pageTitles.covid19FAQ',
          // },
          {
            to: getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE.PREPAYMENT_CALCULATOR),
            textKey: 'pageTitles.prepaymentCalculator',
            isPartiallyActive: true,
          },
          {
            to: getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE.MORTGAGE_SERVICE_DOCUMENTS),
            textKey: 'pageTitles.mortgageServiceDocuments',
          },
          {
            to: getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE.RESOURCES.FINANCIAL_LITERACY),
            textKey: 'pageTitles.financialLiteracy',
          },
          {
            to: getPathFromRouteKey(RouteKeys.CUSTOMER_CENTRE.RESOURCES.FINANCIAL_TOOLS_RESOURCES),
            textKey: 'pageTitles.financialTools',
          },
        ],
      },
      // {
      //   to: getPathFromRouteKey(RouteKeys.CCP),
      //   textKey: 'pageTitles.promotions',
      // },
    ],
  },
];

export default links;
